import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Header from './Header'; // Ensure these components are defined
import QuestionButton from './QuestionButton';
import Footer from './Footer';
import './App.css'; // And your CSS file

function App() {
  const [message, setMessage] = useState('');
  const [interactionHistory, setInteractionHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add a loading state

  const handleQuestionClick = (question) => {
    setMessage(question); // Set the message to the question text
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value); // Update the message state with the new input
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage(); // Call the send message function when Enter is pressed
    }
  };

  const handleSendMessage = async () => { // Make sure this is an async function
    setIsLoading(true); // Start loading
    try {
      const response = await fetch('/startup-navigator/api/chat', { // Adjust the endpoint as needed
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: message }),
      });

      const data = await response.json();
      const apiResponse = data.response; // Adjust according to the actual response structure

      // Update the interaction history with the new message and response
      setInteractionHistory([
        ...interactionHistory,
        { userMessage: message, apiResponse: apiResponse }
      ]);
    } catch (error) {
      console.error("Error:", error);
      // Handle any errors here, like showing a user-friendly message
    }

    setIsLoading(false); // End loading
    // Clear the message input
    setMessage('');
  };

  const questions = [
    "How can I create a unique value proposition for my startup?",
    "Can you help me understand when to pivot my startup?",
    "What should be my focus while building an MVP?",
    "How do I balance rapid iteration and visionary thinking?"
  ];

  return (
    <div className="app">
      <Header/>
      {
        interactionHistory.length === 0 ? 
          <div className="questions">
            {questions.map((question, index) => (
              <QuestionButton key={index} text={question} onClick={() => handleQuestionClick(question)} />
            ))}
          </div> : 
          <div className="interactions">
            {interactionHistory.map((interaction, index) => (
              <div key={index} className="interaction">
                <div className="user-message">You: {interaction.userMessage}</div>
                <div className="api-response">Startup Navigator:
                  {/* Render the API response as formatted markdown */}
                  <ReactMarkdown children={interaction.apiResponse} remarkPlugins={[remarkGfm]} />
                </div>
              </div>
            ))}
          </div>
      }
      <input
        type="text"
        value={message}
        onChange={handleMessageChange}
        onKeyPress={handleKeyPress} // Add the key press handler here
        placeholder="Message Startup Navigator... Press enter after entering text..."
        className="message-input"
      />
      <Footer isLoading={isLoading}/> {/* Pass isLoading as a prop */}
    </div>
  );
}

export default App;

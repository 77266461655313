import React from 'react';
import './Header.css'; // You should create a corresponding CSS file

function Header() {
  return (
    <header className="header">
      <img src="/startup_navigator.png" alt="Startup Navigator Logo" className="logo" />
      <h1>Startup Navigator</h1>
      <p>Guides startups on market dynamics and key methodologies</p>
      <p>By Jarmo Pekka Lumpus</p>
    </header>
  );
}

export default Header;
import React from 'react';
import './Footer.css'; // Assuming you have styles defined here

const Footer = ({ isLoading }) => {
  return (
    <div className="footer">
      {isLoading && <div className="progress-bar">
        <div className="progress"></div>
      </div>}
      <p>Startup Navigator can make mistakes. Consider checking important information.</p>
    </div>
  );
};

export default Footer;


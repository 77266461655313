import React from 'react';
import './QuestionButton.css'; // You should create a corresponding CSS file

function QuestionButton({ text, onClick }) {
  return (
    <button className="question-button" onClick={onClick}>
      {text}
    </button>
  );
}

export default QuestionButton;
